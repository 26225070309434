import * as React from "react"
import { Link, navigate } from "gatsby"
import { useEffect, useRef, useState } from "react"
import Layout from "../../Layout/layout"
import Seo from "../../seo"
import AxiosInstance from "../../../services/axiosInstance"
import { ASAP, taskStatusesData, taskTimeData, taskUrgencyData } from "../taskHelper"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Tags from "./tags"
import moment from "moment"
import { DATE_FORMAT } from "../../Calendar/eventHelper"
import "../task_detail.css"
import { Editor } from "@tinymce/tinymce-react"
import { CONTACT_TYPE_HUMAN } from "../../Contacts/contactHelper"
import {
  PRIVACY_PUBLIC,
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
} from "../../../services/globalStaticData"
import PeopleAndPermissionPanel from "./peopleAndPermissionPanel"
import FilesPanel from "./filesPanel"
import { getUserCurrency } from "../../../services/globalHelpers"

if (typeof window !== "undefined") {
  require("tinymce/tinymce")
  require("tinymce/icons/default")
  require("tinymce/themes/silver")
  require("tinymce/plugins/link")
  require("tinymce/plugins/image")
  require("tinymce/plugins/advlist")
  require("tinymce/plugins/autolink")
  require("tinymce/plugins/lists")
  require("tinymce/plugins/preview")
  require("tinymce/plugins/searchreplace")
  require("tinymce/plugins/table")
  require("tinymce/plugins/wordcount")
  require("tinymce/models/dom/model")
}

const Detail = ({ selectedId }) => {
  const [title, setTitle] = useState(""),
    [status, setStatus] = useState("New"),
    [statusClass, setStatusClass] = useState("new"),
    [statusIcon, setStatusIcon] = useState("app"),
    [estimatedTime, setEstimatedTime] = useState("1 Hour"),
    [estimatedTimeClass, setEstimatedTimeClass] = useState("time_15_min"),
    [urgency, setUrgency] = useState(ASAP),
    [urgencyClass, setUrgencyClass] = useState("asap"),
    [urgencyIcon, setUrgencyIcon] = useState("heart-fill"),
    [projectTypes, setProjectTypes] = useState([]),
    [isShowStatus, setIsShowStatus] = useState(false),
    [isShowUrgency, setIsShowUrgency] = useState(false),
    [isShowEstimatedTime, setIsShowEstimatedTime] = useState(false),
    [transaction, setTransaction] = useState(""),
    [transactionLabel, setTransactionLabel] = useState("Task"),
    [transactionIcon, setTransactionIcon] = useState("check-square"),
    [taskStartDate, setTaskStartDate] = useState(""),
    [taskEndDate, setTaskEndDate] = useState(""),
    [taskDuration, setTaskDuration] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [taskStatusesNewData, setTaskStatusesNewData] = useState(taskStatusesData),
    [isDefaultStatusData, setIsDefaultStatusData] = useState(true),
    [transactionTypeIcon, setTransactionTypeIcon] = useState("check-square"),
    [transactionTypeIconColor, setTransactionTypeIconColor] = useState("#fff"),
    [transactionTypeIconBgColor, setTransactionTypeIconBgColor] = useState("#1658b8"),
    [content, setContent] = useState(""),
    [showDescription, setShowDescription] = useState(false),
    [showCalendar, setShowCalendar] = useState(false),
    [metaJson, setMetaJson] = useState(""),
    [transactionTypeStatusData, setTransactionTypeStatusData] = useState([]),
    [dynamicWidth, setDynamicWidth] = useState(100),
    [showTags, setShowTags] = useState(false),
    [isShowPeoplePanel, setIsShowPeoplePanel] = useState(false),
    [defaultSelectedRole, setDefaultSelectedRole] = useState(""),
    [uploadedFile, setUploadedFile] = useState(null),
    [isContactSubmit, setIsContactSubmit] = useState(false),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type),
    [taskContactData, setTaskContactData] = useState([]),
    [address, setAddress] = useState(""),
    [workflowStatus, setWorkflowStatus] = useState([]),
    [contactSelectedAddress, setContactSelectedAddress] = useState(""),
    [taskWorkRoom, setTaskWorkRoom] = useState([]),
    [isShowFilePanel, setIsShowFilePanel] = useState(false),
    [showMoney, setShowMoney] = useState(false),
    [moneyData, setMoneyData] = useState([]),
    [calendarId, setCalendarId] = useState(null)

  const editorRef = useRef(null)

  const getTask = selectedId => {
    return new Promise(async () => {
      await AxiosInstance.get(`/task/detail/${selectedId}`).then(function (response) {
        if (response.status === 200) {
          const task = response.data.task

          const statusData = taskStatusesData.find(status => status.label === task.status)
          const estimatedTimeData = taskTimeData.find(taskTime => taskTime.LABEL === task.estimatedTime)
          const urgencyData = taskUrgencyData.find(urgency => urgency.LABEL === task.urgency)

          setTitle(task.title)
          if (task.transactionType) {
            setTransaction(task.transactionType.id)
            setTransactionIcon(task.transactionType.icon)
            setTransactionLabel(task.transactionType.dealTypeLabel)
            setTransactionTypeIcon(task.transactionType.icon)
            setTransactionTypeIconColor(task.transactionType.iconColor)
            setTransactionTypeIconBgColor(task.transactionType.iconBackgroundColor)
            setIsDefaultStatusData(false)
            handleTransactionChange(task.transactionType.id)
          } else {
            setStatus(task.status)
            setStatusClass(statusData.class)
            setStatusIcon(statusData.icon)
            setIsDefaultStatusData(true)
          }

          if (task.estimatedTime) {
            setEstimatedTime(task.estimatedTime)
            setEstimatedTimeClass(estimatedTimeData.CLASS)
          }
          if (task.urgency) {
            setUrgency(task.urgency)
            setUrgencyClass(urgencyData.CLASS)
            setUrgencyIcon(urgencyData.ICON)
          }

          setTags(task.tags ? task.tags : [])
          setTempTags(task.tags ? task.tags : [])

          if (task.calendars[0]?.startTime) {
            setTaskStartDate(moment(task.calendars[0]?.startTime).format(DATE_FORMAT))
          }

          if (task.calendars[0]?.endTime) {
            setTaskEndDate(moment(task.calendars[0]?.endTime).format(DATE_FORMAT))
          }

          if (task.calendars[0]?.startTime && task.calendars[0]?.endTime) {
            setTaskDuration(
              moment(task.calendars[0]?.endTime).diff(moment(task.calendars[0]?.startTime), "minutes")
            )
          }

          setShowDescription(task.metaJson ? task.metaJson.show_description : false)
          setShowCalendar(task.metaJson?.show_calendar ? task.metaJson.show_calendar : false)
          setContent(task.note ? task.note : "")
          setMetaJson(task.metaJson ? task.metaJson : [])
          setWorkflowStatus(task.metaJson?.workflow_status ? task.metaJson.workflow_status : [])
          setTaskContactData(task.taskContacts ? task.taskContacts : [])
          setShowMoney(task.metaJson?.show_money ? task.metaJson.show_money : false)
          setMoneyData(task.metaJson?.money_details ? task.metaJson.money_details : [])
          setContactSelectedAddress(task.metaJson?.location ? task.metaJson.location : false)
          setCalendarId(task.calendars[0]?.id)
          setIsShowPeoplePanel(task.metaJson ? task.metaJson.show_people_and_roles : false)
          setIsShowFilePanel(task.metaJson ? task.metaJson.show_files : false)
          setTaskWorkRoom(task.workroom)
        }
      })
    })
  }

  const submitTask = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    if (isContactSubmit) {
      if (formData.get("contactName") === "") {
        alert("Please enter Contact Name.")
        return false
      }

      let contactData = {
        name: formData.get("contactName"),
        email: formData.get("contactEmail"),
        profilePic: uploadedFile,
        companyName: formData.get("companyName"),
        jobTitle: formData.get("jobTitle"),
        contactType: CONTACT_TYPE_HUMAN,
        phone: {
          type: selectedPhoneType,
          phone: formData.get("contactPhone"),
          permission: PRIVACY_PUBLIC,
        },
        address: {
          type: selectedAddressType,
          address: address,
          permission: PRIVACY_PUBLIC,
        },
        metaData: [],
        customFields: [],
      }

      return new Promise(async () => {
        try {
          const response = await AxiosInstance.post(`/contact/add`, contactData)
          if (response.status === 201) {
            await AxiosInstance.post(
              `task/assign-task-contact/${selectedId}/${response.data.contact}/${defaultSelectedRole}`
            )
            getTask(selectedId)
            setAddress("")
            e.target.reset()
          }
        } catch (error) {
          console.error(error)
          alert("There is some error. Please try again later.")
        }
      })
    } else {
      const noteContent = formData.get("content")

      const moneyDetail = {
        single_price: formData.get("singlePrice"),
        invoice_type: formData.get("invoiceType"),
        invoice_status: formData.get("invoiceStatus"),
      }

      const updatedMetaJson = {
        // task_note: noteContent,
        show_description: showDescription,
        show_calendar: showCalendar,
        workflow_status: workflowStatus,
        show_money: showMoney,
        money_details: moneyDetail,
        location: contactSelectedAddress,
        show_people_and_roles: isShowPeoplePanel,
        show_files: isShowFilePanel,
      }

      setMetaJson(updatedMetaJson)

      let endTime = null
      if (taskStartDate) {
        endTime =
          taskDuration === "Custom"
            ? moment(formData.get("endTime")).format(DATE_FORMAT)
            : moment(taskStartDate).add(taskDuration, "minutes").format(DATE_FORMAT)
      }

      let taskEdiDataObj = {
        title: formData.get("title"),
        urgency: urgency,
        estimatedTime: estimatedTime,
        tags: tempTags,
        startTime: taskStartDate ? moment(taskStartDate).format(DATE_FORMAT) : null,
        endTime,
        note: noteContent,
        metaJson: updatedMetaJson,
      }

      if (!isDefaultStatusData) {
        taskEdiDataObj.transactionType = transaction
      } else {
        taskEdiDataObj.status = status
      }

      AxiosInstance.post(`/task/edit/${selectedId}`, taskEdiDataObj).catch(function (error) {
        console.log(error)
      })
    }
  }

  const projectTypesData = () => {
    AxiosInstance.get("/settings/transaction-type")
      .then(response => {
        setProjectTypes(response.data.items)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const showHideTaskDropdown = (activeStatus, taskFor) => {
    if (taskFor == "status") {
      setIsShowStatus(activeStatus)
    } else if (taskFor == "urgency") {
      setIsShowUrgency(activeStatus)
    } else if (taskFor == "estimatedTime") {
      setIsShowEstimatedTime(activeStatus)
    }
  }

  const handleTransactionChange = transactionTypeId => {
    if (transactionTypeId) {
      // For Transaction
      setIsDefaultStatusData(false)
      return new Promise(async () => {
        await AxiosInstance.get(`/settings/transaction-type/detail/${transactionTypeId}`).then(function (
          response
        ) {
          if (response.status === 200) {
            const transactionType = response.data.type

            let statusFilteredData = transactionType.statuses.filter(item => !item.additionalType)
            statusFilteredData.push({ id: "trophy", icon: "trophy-fill", status: "Pending" })
            setTransactionTypeStatusData(statusFilteredData)
          }
        })
      })
    } else {
      // For Task
      setIsDefaultStatusData(true)
    }
  }

  const openAddressOnGoogleMap = () => {
    const googleMapsUrl = `https://www.google.com/maps/dir//${contactSelectedAddress}`
    window.open(googleMapsUrl, "_blank")
  }

  const deleteTaskEvent = () => {
    if (!calendarId) {
      alert("Event not exists for this Job.")
      return false
    }

    if (!window.confirm("Delete this event?")) {
      return false
    }
    return new Promise(async () => {
      await AxiosInstance.delete(`/calendar/` + calendarId).then(response => {
        setTaskStartDate("")
        setTaskDuration("")
      })
    })
  }

  const deleteTask = () => {
    if (!window.confirm("Delete this Job?")) {
      return false
    }
    return new Promise(async () => {
      await AxiosInstance.delete(`/task/` + selectedId).then(response => {
        navigate("/jobs")
      })
    })
  }

  useEffect(() => {
    getTask(selectedId)
    projectTypesData()
  }, [])

  useEffect(() => {
    let workflow = transactionTypeStatusData.map((item, index) => {
      return {
        id: item.id,
        status:
          workflowStatus.length > 0 && workflowStatus[index]?.id === item.id
            ? workflowStatus[index].status
            : "Pending",
      }
    })
    setWorkflowStatus(workflow)
  }, [transactionTypeStatusData])

  const currencyData = getUserCurrency()
  const currencySymbol = currencyData.length === 0 ? "$" : currencyData.symbol

  return (
    <Layout>
      <Seo title={title} />
      <div id="right-section" className="h-100">
        <div className="row g-0" id="taskDetailTemplate">
          <form onSubmit={submitTask} method="POST">
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-12 eventDetailLeftSection">
                  <div className="row g-0">
                    <div className="col-sm-7 mb-3">
                      <div className="pageTitle_Editor">
                        <Link to={"/jobs"} className="goBack">
                          <i className="bi bi-arrow-90deg-left" />
                        </Link>
                        <Link
                          to={"/home"}
                          className="CD_UserType"
                          style={{
                            color: transactionTypeIconColor,
                            backgroundColor: transactionTypeIconBgColor,
                          }}
                        >
                          <i className={`bi bi-${transactionTypeIcon}`} />
                        </Link>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="Title / Summary"
                          required
                          defaultValue={title}
                          style={{ paddingLeft: "105px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3 contactDetailsContainer">
                      <div className="row g-0">
                        <div className="col position-relative">
                          <div className="event_tabs">
                            <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                              <li className="nav-item">
                                <div className="btn-group btnAddNewDropdown" role="group">
                                  <Link to={"/jobs?addJob=1"} className="btn btn-primary">
                                    <i className="bi bi-plus-lg" /> Add New
                                  </Link>

                                  <button
                                    type="button"
                                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <span className="visually-hidden">Toggle Dropdown</span>
                                  </button>
                                  <ul className="dropdown-menu" data-popper-placement="bottom-start">
                                    <li>
                                      <Link to={"/jobs?addJob=1"} className="dropdown-item">
                                        <i className="bi bi-check-square" /> Task / Project
                                      </Link>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-folder-fill"></i> Folder /{" "}
                                        <i className="bi bi-people-fill"></i> Workroom
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </li>

                              <li className="nav-item" role="presentation">
                                <OverlayTrigger placement="top" overlay={<Tooltip>Task List</Tooltip>}>
                                  <Link
                                    to={"/jobs"}
                                    className="nav-link active goBack"
                                    id="PrioritizedListView-tab"
                                  >
                                    <i className="bi bi-list-ol" />
                                  </Link>
                                </OverlayTrigger>
                              </li>

                              <li className="nav-item" role="presentation">
                                <OverlayTrigger placement="top" overlay={<Tooltip>Pipeline View</Tooltip>}>
                                  <Link to={"/jobs?view=pipeline"} className="nav-link">
                                    <i className="bi bi-bar-chart-fill rotate-270" />
                                  </Link>
                                </OverlayTrigger>
                              </li>

                              <li className="nav-item nav-item-trash" role="presentation">
                                <a
                                  className="nav-link"
                                  id="tasks-trash-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#tasks-trash-pane"
                                  role="tab"
                                  aria-controls="tasks-trash-pane"
                                  aria-selected="false"
                                >
                                  <span data-bs-toggle="tooltip" title="Trash">
                                    <i className="bi bi-trash-fill" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="taskStatusRow p-3">
                            <div className="taskStatusAndTagsContainer">
                              <div className="taskStatusContainer">
                                <div
                                  className="btn-group"
                                  role="group"
                                  id="transactionTypeDropdownContainer"
                                  style={{ marginRight: "3px" }}
                                >
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle transactionTypeDropdownContainer"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i
                                      className={`bi bi-${
                                        transactionLabel !== "Task" ? transactionIcon : "check-square"
                                      }`}
                                    />{" "}
                                    <span className="d-none d-md-inline"> {transactionLabel}</span>
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li
                                      value=""
                                      onClick={() => {
                                        handleTransactionChange("")
                                        setTransactionLabel("Task")
                                        setIsDefaultStatusData(true)
                                      }}
                                    >
                                      <a className="dropdown-item" href="#">
                                        <span>
                                          <i className="bi bi-check-square"></i>
                                        </span>{" "}
                                        Task
                                      </a>
                                    </li>

                                    {projectTypes.map((projectType, index) => (
                                      <li
                                        key={index}
                                        value={projectType.id}
                                        onClick={() => {
                                          setTransaction(projectType.id)
                                          setTransactionLabel(projectType.dealTypeLabel)
                                          setTransactionIcon(projectType.icon)
                                          setIsDefaultStatusData(false)
                                          handleTransactionChange(projectType.id)
                                        }}
                                      >
                                        <a className="dropdown-item" href="#">
                                          <span>
                                            <i className={`bi bi-${projectType.icon}`}></i>
                                          </span>{" "}
                                          {projectType.dealTypeLabel}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>

                                {isDefaultStatusData && (
                                  <div
                                    className="btn-group"
                                    role="group"
                                    id="statusContainer"
                                    style={{ marginRight: "3px" }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn dropdown-toggle statusContainer taskStatus_${statusClass}`}
                                      data-bs-toggle={isShowStatus ? "dropdown" : ""}
                                      aria-expanded="false"
                                      onClick={() => showHideTaskDropdown(true, "status")}
                                    >
                                      <i className={`bi bi-${statusIcon}`} /> {status}
                                    </button>
                                    <ul
                                      className={
                                        isShowStatus
                                          ? "dropdown-menu hiddenStatus show"
                                          : "dropdown-menu hiddenStatus"
                                      }
                                    >
                                      {taskStatusesNewData.map((taskStatus, index) => (
                                        <li
                                          key={index}
                                          onClick={() => {
                                            setStatus(taskStatus.label)
                                            setStatusClass(taskStatus.class)
                                            setStatusIcon(taskStatus.icon)
                                          }}
                                        >
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() => showHideTaskDropdown(false, "status")}
                                          >
                                            <span className={`taskStatus_${taskStatus.class}`}>
                                              <i className={`bi bi-${taskStatus.icon}`} />
                                            </span>{" "}
                                            {taskStatus.label}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}

                                <div
                                  className="btn-group"
                                  role="group"
                                  id="estimatedTimeContainer"
                                  style={{ marginRight: "3px" }}
                                >
                                  <button
                                    type="button"
                                    className={`btn dropdown-toggle estimatedTimeContainer estimated_${estimatedTimeClass}`}
                                    data-bs-toggle={isShowEstimatedTime ? "dropdown" : ""}
                                    aria-expanded="false"
                                    onClick={() => showHideTaskDropdown(true, "estimatedTime")}
                                  >
                                    <i className="bi bi-clock" /> {estimatedTime}
                                  </button>
                                  <ul
                                    className={
                                      isShowEstimatedTime
                                        ? "dropdown-menu hiddenStatus show"
                                        : "dropdown-menu hiddenStatus"
                                    }
                                  >
                                    {taskTimeData.map((taskTime, index) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          setEstimatedTime(taskTime.LABEL)
                                          setEstimatedTimeClass(taskTime.CLASS)
                                        }}
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() => showHideTaskDropdown(false, "estimatedTime")}
                                        >
                                          <span className={`estimated_${taskTime.CLASS}`}>
                                            <i className="bi bi-clock" />
                                          </span>{" "}
                                          {taskTime.LABEL}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div
                                  className="btn-group addSpaceForDropdown"
                                  role="group"
                                  id="urgencyStatusContainer"
                                >
                                  <button
                                    type="button"
                                    className={`btn dropdown-toggle urgencyStatusContainer urgencyStatus_${urgencyClass}`}
                                    data-bs-toggle={isShowUrgency ? "dropdown" : ""}
                                    aria-expanded="false"
                                    onClick={() => showHideTaskDropdown(true, "urgency")}
                                  >
                                    <i className={`bi bi-${urgencyIcon}`} /> {urgency}
                                  </button>

                                  <ul
                                    className={
                                      isShowUrgency
                                        ? "dropdown-menu hiddenStatus show"
                                        : "dropdown-menu hiddenStatus"
                                    }
                                  >
                                    {taskUrgencyData.map((taskUrgency, index) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          setUrgency(taskUrgency.LABEL)
                                          setUrgencyClass(taskUrgency.CLASS)
                                          setUrgencyIcon(taskUrgency.ICON)
                                        }}
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() => showHideTaskDropdown(false, "urgency")}
                                        >
                                          <span className={`urgencyStatus_${taskUrgency.CLASS}`}>
                                            <i className={`bi bi-${taskUrgency.ICON}`} />
                                          </span>{" "}
                                          {taskUrgency.LABEL}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="taskTagsContainer">
                                <div className="CD_Info_Row eventTagsAndDealTypeContainer position-relative">
                                  <div className="taskdetail_Tags_Container">
                                    <Tags
                                      tags={tags}
                                      setTags={setTags}
                                      tempTags={tempTags}
                                      setTempTags={setTempTags}
                                      showTags={showTags}
                                      setShowTags={setShowTags}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row g-0">
                            <div className="col-lg-12">
                              <div className="taskDetailStatusBar">
                                <ul className="workflow-wizard hasFeaturedContact">
                                  {transactionTypeStatusData.map((transactionData, transactionIndex) => {
                                    const status = `SaleStatus_${
                                      workflowStatus.length > 0 &&
                                      workflowStatus[transactionIndex]?.id === transactionData.id
                                        ? workflowStatus[transactionIndex].status
                                        : "Pending"
                                    }`
                                    return (
                                      <OverlayTrigger
                                        overlay={
                                          transactionData.label ? (
                                            <Tooltip>{transactionData.label}</Tooltip>
                                          ) : (
                                            <span></span>
                                          )
                                        }
                                      >
                                        <li
                                          className={status}
                                          key={transactionIndex}
                                          style={{
                                            width: `${dynamicWidth / transactionTypeStatusData.length}%`,
                                          }}
                                          id={transactionIndex}
                                        >
                                          <i className={`bi bi-${transactionData.icon}`} />
                                          <select
                                            className="form-select workflow-wizard-select"
                                            defaultValue={status}
                                            onChange={e => {
                                              const newStatus = e.target.value
                                              const parentLi = e.target.parentNode
                                              parentLi.className = newStatus

                                              workflowStatus[transactionIndex] = {
                                                id: workflowStatus[transactionIndex].id,
                                                status: newStatus.replace("SaleStatus_", ""),
                                              }
                                              setWorkflowStatus([...workflowStatus])
                                            }}
                                          >
                                            <option value="SaleStatus_Pending">Not Started</option>
                                            <option value="SaleStatus_Processing">
                                              In Process / Pending
                                            </option>
                                            <option value="SaleStatus_Complete">Complete</option>
                                            <option value="SaleStatus_Error">
                                              Problem / Other / See Notes
                                            </option>
                                          </select>
                                        </li>
                                      </OverlayTrigger>
                                    )
                                  })}

                                  {/*{transactionTypeStatusData.length > 0 &&
                                    <li className="SaleStatus_Pending" style={{ width: "10%" }}>
                                      <i className="bi bi-trophy-fill" />
                                      <select
                                        className="form-select workflow-wizard-select"
                                        onChange={e => {
                                          const trophyNewStatus = e.target.value
                                          const trophyParentLi = e.target.parentNode
                                          trophyParentLi.className = trophyNewStatus
                                        }}
                                      >
                                        <option value="SaleStatus_Pending" selected="">
                                          Not Started
                                        </option>
                                        <option value="SaleStatus_Processing">In Process / Pending</option>
                                        <option value="SaleStatus_Complete">Complete</option>
                                        <option value="SaleStatus_Error">Problem / Other / See Notes</option>
                                      </select>
                                    </li>
                                  }*/}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="eventTimeTagRow">
                            <div className="row">
                              <div className="col-lg-12">
                                <div
                                  className={`row row-cols-lg-auto p-3 g-1 align-items-center eventDateRow`}
                                >
                                  {showCalendar && (
                                    <>
                                      <hr />

                                      <div className="teamMember">
                                        <OverlayTrigger overlay={<Tooltip>Remove Event?</Tooltip>}>
                                          <Link
                                            to={"#"}
                                            className="btnRemoveEventUser"
                                            onClick={e => {
                                              e.preventDefault()
                                              deleteTaskEvent()
                                            }}
                                          >
                                            <i className="bi bi-x-lg" />
                                          </Link>
                                        </OverlayTrigger>

                                        <div className="row row-cols-lg-auto p-3 g-1 align-items-center">
                                          <div className="col-12">
                                            <input
                                              type="datetime-local"
                                              className="form-control form-control-lg task_start_datetime"
                                              name="task_start_datetime"
                                              placeholder="Start Date/Time"
                                              value={taskStartDate}
                                              step="900"
                                              onChange={e => {
                                                setTaskStartDate(e.target.value)
                                              }}
                                            />
                                          </div>

                                          <div className="col-12">
                                            <select
                                              className="form-select form-select-lg taskTimeDurationInput"
                                              key={taskDuration}
                                              defaultValue={taskDuration}
                                              onChange={e => {
                                                setTaskDuration(e.target.value)
                                              }}
                                            >
                                              <option value="All Day">All Day</option>
                                              <optgroup label="== Duration==">
                                                <option value="1">1 min</option>
                                                <option value="6">6 mins</option>
                                                <option value="15">15 mins</option>
                                                <option value="30">30 mins</option>
                                                <option value="45">45 mins</option>
                                                <option value="60">1 hr</option>
                                                <option value="120">2 hrs</option>
                                                <option value="240">4 hrs</option>
                                                <option value="360">6 hrs</option>
                                                <option value="720">12 hrs</option>
                                                <option value="1440">24 hrs</option>
                                                <option value="Custom">Custom</option>
                                              </optgroup>
                                            </select>
                                          </div>
                                          {taskDuration === "Custom" && (
                                            <div className="col-12 taskTimeDurationCustomInput">
                                              <input
                                                type="datetime-local"
                                                name="endTime"
                                                step="900"
                                                className="form-control form-control-lg task_end_datetime"
                                                placeholder="End Date/Time"
                                                defaultValue={taskEndDate}
                                                required={taskDuration === "Custom"}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {showMoney && (
                            <div className="addMoneyRow">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="row row-cols-sm-auto p-3 g-1 border-top align-items-center eventDateRow">
                                    <div className="col-12 mt-0">
                                      <div className="input-group">
                                        <span className="input-group-text">{currencySymbol}</span>
                                        <input
                                          className="form-control"
                                          type="number"
                                          name="singlePrice"
                                          defaultValue={moneyData.single_price}
                                        />
                                        <select
                                          className="form-select"
                                          name="invoiceType"
                                          id="invoiceTypeOpts"
                                          value={moneyData.invoice_type}
                                          onChange={e =>
                                            setMoneyData({ ...moneyData, invoice_type: e.target.value })
                                          }
                                        >
                                          <option value="Invoice">Invoice</option>
                                          <option value="Estimate">Estimate</option>
                                          <option value="Proposal">Proposal</option>
                                          <option value="Quote">Quote</option>
                                          <option value="Purchase_Order">Purchase Order</option>
                                          <option value="Credit_Memo">Credit Memo</option>
                                          <option value="Receipt">Receipt</option>
                                        </select>
                                        <select
                                          className="form-select"
                                          name="invoiceStatus"
                                          id="invoice_status_opts"
                                          value={moneyData.invoice_status}
                                          onChange={e =>
                                            setMoneyData({ ...moneyData, invoice_status: e.target.value })
                                          }
                                        >
                                          <option value="Due">Due</option>
                                          <option value="Paid_Partial">Paid Partial</option>
                                          <option value="Paid">PAID</option>
                                          <option value="Payment_Pending">Payment Pending</option>
                                          <option value="Canceled">Canceled</option>
                                          <option value="Refunded">Refunded</option>
                                          <option value="Unpaid_Overdue">Unpaid Overdue</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3">
                      <div className="row g-0">
                        <div
                          className={`col-lg-12 mb-3 eventDescToggle${!showDescription ? " d-none" : ""}`}
                          id="editor_panel"
                        >
                          <div className="accordion-item">
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="doc_data">
                                    <Editor
                                      textareaName="content"
                                      value={content}
                                      onInit={(evt, editor) => (editorRef.current = editor)}
                                      init={{
                                        skin: false,
                                        branding: false,
                                        content_css: false,
                                        height: 500,
                                        promotion: false,
                                        plugins: [
                                          "advlist",
                                          "autolink",
                                          "lists",
                                          "link",
                                          "image",
                                          "preview",
                                          "searchreplace",
                                          "table",
                                          "lists",
                                          "link",
                                          "wordcount",
                                        ],
                                        toolbar:
                                          "undo redo| styles | " +
                                          "bold italic alignleft aligncenter " +
                                          "alignright alignjustify | bullist numlist outdent indent | image |",
                                        content_style:
                                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                      }}
                                      onEditorChange={(newValue, editor) => {
                                        setContent(editor.getContent())
                                        // setMetaJson({ ...metaJson, task_note: content })
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-lg-12 mb-3${!isShowPeoplePanel ? " d-none" : ""}`}
                          id="people_panel"
                        >
                          <PeopleAndPermissionPanel
                            taskContactData={taskContactData}
                            setIsContactSubmit={setIsContactSubmit}
                            selectedId={selectedId}
                            defaultSelectedRole={defaultSelectedRole}
                            setDefaultSelectedRole={setDefaultSelectedRole}
                            getTask={getTask}
                            setSelectedPhoneType={setSelectedPhoneType}
                            setSelectedAddressType={setSelectedAddressType}
                            uploadedFile={uploadedFile}
                            setUploadedFile={setUploadedFile}
                            address={address}
                            setAddress={setAddress}
                          />
                        </div>

                        <div className={`col-lg-12 mb-3${!isShowFilePanel ? " d-none" : ""}`} id="file_panel">
                          <FilesPanel driveAllId={taskWorkRoom?.id} />
                        </div>

                        <div className="col-lg-12 footer-action">
                          <a href="#" className="btnDeleteEvent" onClick={deleteTask}>
                            <i className="bi bi-trash" /> Delete this Job
                          </a>
                        </div>

                        <div className="col-lg-12 footer-action">
                          <div className="row row-cols-sm-auto g-1 align-items-center justify-content-center mb-3">
                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger placement="top" overlay={<Tooltip>Tags</Tooltip>}>
                                <button
                                  type="button"
                                  className={`btnEditPrivateTags`}
                                  onClick={() => {
                                    setShowTags(!showTags)
                                    window.scrollTo(0, 0)
                                  }}
                                >
                                  <i className="bi bi-tag-fill" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger placement="top" overlay={<Tooltip>Schedule Task</Tooltip>}>
                                <button
                                  type="button"
                                  className={`btnAddEvent${showCalendar ? " active" : ""}`}
                                  onClick={() => {
                                    setShowCalendar(!showCalendar)
                                  }}
                                >
                                  <i className="bi bi-calendar-plus" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger placement="top" overlay={<Tooltip>Add Description</Tooltip>}>
                                <button
                                  type="button"
                                  className={`event_btnAddEventDesc${showDescription ? " active" : ""}`}
                                  onClick={() => {
                                    setShowDescription(!showDescription)
                                  }}
                                >
                                  <i className="bi bi-justify-left" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delegate or Add Team</Tooltip>}
                              >
                                <button
                                  type="button"
                                  className={`event_btnAddNewGuestUser${isShowPeoplePanel ? " active" : ""}`}
                                  onClick={() => {
                                    setIsShowPeoplePanel(!isShowPeoplePanel)
                                  }}
                                >
                                  <i className="bi bi-person-fill-add" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Location and/or Channel</Tooltip>}
                              >
                                <div className="dropdown addEventOccuranceDropdown">
                                  <button
                                    className={`btn dropdown-toggle no-dropdown-arrow event_btnAddEventOccurance${
                                      contactSelectedAddress ? " active" : ""
                                    }`}
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-geo-alt-fill" />
                                  </button>
                                  <ul className="dropdown-menu">
                                    {taskContactData.map((taskContactValue, taskContactIndex) => (
                                      <React.Fragment key={taskContactIndex}>
                                        {taskContactValue.contact.addresses?.map(
                                          (addressValue, addressIndex) => (
                                            <li key={addressIndex}>
                                              <Link
                                                to={"#"}
                                                className="dropdown-item"
                                                data-type="SettingsAddresses"
                                                onClick={() =>
                                                  setContactSelectedAddress(addressValue.address)
                                                }
                                              >
                                                {addressValue.address}
                                              </Link>
                                            </li>
                                          )
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </ul>
                                </div>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger placement="top" overlay={<Tooltip>Files</Tooltip>}>
                                <button
                                  type="button"
                                  className={`event_btnAddNewGuestUser${isShowFilePanel ? " active" : ""}`}
                                  onClick={() => {
                                    setIsShowFilePanel(!isShowFilePanel)
                                  }}
                                >
                                  <i className="bi bi-paperclip" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger placement="top" overlay={<Tooltip>Add Money Details</Tooltip>}>
                                <button
                                  type="button"
                                  className={`btnAddMoney${showMoney ? " active" : ""}`}
                                  onClick={() => {
                                    setShowMoney(!showMoney)
                                    window.scrollTo(0, 0)
                                  }}
                                >
                                  {currencySymbol}
                                </button>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 text-center footer-action">
                          <div className="btn-group btnDropdownWithSeparater">
                            <button
                              type="submit"
                              value="taskSave"
                              className="btn btn-primary btn-lg"
                              onClick={() => {
                                setIsContactSubmit(false)
                              }}
                            >
                              <i className="bi bi-check2" /> Save
                            </button>
                          </div>{" "}
                          {contactSelectedAddress && (
                            <button
                              type="button"
                              className="btn btn-success btn-lg btnGetDirection"
                              onClick={openAddressOnGoogleMap}
                            >
                              <i className="bi bi-geo-alt-fill" /> Get Directions
                            </button>
                          )}
                          <button type="button" className="btn btn-secondary btn-lg btnEventPreview d-none">
                            <i className="bi bi-box-arrow-up-right" /> Preview
                          </button>
                          <button type="button" className="btn btn-secondary btn-lg btnJoinMeeting d-none">
                            <i className="bi bi-box-arrow-up-right" /> Join Meeting
                          </button>
                          <button type="button" className="btn btn-default btn-lg btnEventPublish d-none">
                            <i className="bi bi-cloud-arrow-up-fill" /> Publish
                          </button>{" "}
                          <OverlayTrigger placement="top" overlay={<Tooltip>History</Tooltip>}>
                            <button type="button" className="btn btn-default btn-lg btnOpenChatDock">
                              <i className="bi bi-arrow-counterclockwise" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Detail
